import React, { FC } from 'react';
import { useId } from 'react-id-generator';
import { graphql } from 'gatsby';

import { Layout } from 'layout';
import Banner from 'components/Banner';
import { BannerMode } from 'components/Banner/models.d';
import PageDescription from 'components/PageDescription';
import { bodyAdapter } from 'utils/bodyAdapter';
import BodyRenderer from 'utils/bodyRenderer';
import createBreadcrumbs from 'utils/createBreadcrumbs';

import { ArticleCategoryListingPageProps } from './models';

const ArticleCategoryListingPage: FC<ArticleCategoryListingPageProps> = ({
  data,
  pageContext: {
    breadcrumb: { crumbs },
    areBreadcrumbsDisplayed,
  },
  location: { pathname },
  pageContext: { pagePathname },
}) => {
  const {
    articleCategoryListingPage: { seo, langProps, banner, pageDescription, body },
    allSitePage: { nodes },
  } = data;
  const breadcrumbs = createBreadcrumbs(crumbs, areBreadcrumbsDisplayed, nodes, pathname);
  const adaptedBody = bodyAdapter(body);

  return (
    <Layout {...{ seo, langProps, breadcrumbs, pagePathname }}>
      {banner ? <Banner {...banner[0]} mode={BannerMode.article} /> : null}
      {pageDescription ? <PageDescription {...pageDescription} /> : null}
      {adaptedBody.map((component) => {
        const [key] = useId();

        return <BodyRenderer {...component} key={key} />;
      })}
    </Layout>
  );
};

export const query = graphql`
  query($link: String) {
    articleCategoryListingPage(link: { eq: $link }) {
      seo {
        ...SEOStructureFragment
      }
      langProps {
        urls {
          ...languageUrls
        }
        lang
      }
      banner {
        ...BannerFragment
      }
      pageDescription {
        description
        backgroundColor
      }
      body {
        teaser {
          ...TeaserFragment
        }
        stainsListing {
          ...ListingFragment
        }
        articleCategoryListing {
          ...ListingFragment
        }
        newsletterNodePicker {
          ...NewsletterBannerFragment
        }
        contentListing {
          ...ListingFragment
        }
        textWithPopUp {
          ...TextWithPopUpFragment
        }
        shoppingButtons {
          ...ShoppingButtonsFragment
        }
        articleSection {
          description
          additionalDescription
          secondaryNavigationAnchor
          title
          backgroundColor
          logo {
            ...ImageFragment
          }
          logoAlt
          weRecommended {
            recommendedLabel
            recommended {
              link {
                url
                udi
                name
              }
              linkAriaLabel
              description
              image {
                ...ImageFragment
              }
              alt
            }
          }
          media {
            videoId
            image {
              ...ImageFragment
            }
            alt
          }
          instruction {
            instructionLabel
            instructionMoreButtonLabel
            instructions {
              value
            }
          }
          cards {
            title
            description
          }
        }
      }
    }
    allSitePage {
      ...FragmentAllPageSite
    }
  }
`;

export default ArticleCategoryListingPage;
